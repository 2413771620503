import { Button } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import Page from '../components/Page'
import Style from '../styles/WinWiseAppHome.module.sass'
import { useLanguages } from '../hooks/useLanguages'

const WinWiseAppHome: FC = () => {
  const { t } = useLanguages()

  const renderBrandNewCustomApp = () => {
    return (
      <>
        <h1 className={Style.headerTitle}>WinWise</h1>
        <div className={Style.headerText}>
          <p>{t('winwise_desc')}</p>
          <p>{t('winwise_desc2')}</p>
        </div>
        <div className={Style.winWiseFooter}>
          <Button label={t('create')} cssClass={Style.winWiseButton} />
        </div>
      </>
    )
  }
  return (
    <Page menu contentWrapper>
      {renderBrandNewCustomApp()}
    </Page>
  )
}

export default WinWiseAppHome
